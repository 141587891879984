@import url(https://fonts.googleapis.com/css?family=Raleway&Montserrat);
input{background-color:#f7f7f7 !important;border:1px solid rgba(172,170,170,0.4);-webkit-filter:none !important;filter:none !important;margin-top:1.5rem;padding:1rem}input:focus{border:1px solid #3624696e}.input__field{display:block;width:100%}.input__field--error{border:1px solid #ffa8a8 !important;color:#c52424}

.inputError__message{color:#c52424;font-size:0.7rem;margin-left:1rem}

.input-container__group{width:100%}

.loader{margin:4rem auto}.loader__blocks{height:200px !important;margin:0 auto;position:relative;-webkit-transform:translate(-100px, -100px) scale(1) translate(100px, 100px);transform:translate(-100px, -100px) scale(1) translate(100px, 100px);width:200px !important}.loader__blocks div{position:absolute;width:40px;height:40px;background:#65106e;-webkit-animation:loading 1s linear infinite;animation:loading 1s linear infinite}@-webkit-keyframes loading{0%{background:#ffffff}12.5%{background:#ffffff}12.625%{background:#65106e}100%{background:#65106e}}@keyframes loading{0%{background:#ffffff}12.5%{background:#ffffff}12.625%{background:#65106e}100%{background:#65106e}}

.modal{background-color:#fff;background-size:cover;background-position:center;background-repeat:no-repeat;border-radius:5px;box-shadow:0px 0px 50px 1000px rgba(0,0,0,0.8);height:700px;left:50%;overflow-y:scroll;top:20px;position:fixed;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:90%;z-index:999999}@media (min-width: 1025px){.modal{width:70%}}


.select{display:block;font-size:16px;font-family:sans-serif;font-weight:700;color:#444;line-height:1.3;padding:.6em 1.4em .5em .8em;width:100%;max-width:100%;box-sizing:border-box;margin-top:2.5rem;border:1px solid #aaa;box-shadow:0 1px 0 1px rgba(0,0,0,0.04);border-radius:.5em;-webkit-appearance:none;appearance:none;background-color:#fff;background-image:url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),linear-gradient(to bottom, #fff 0%, #e5e5e5 100%);background-repeat:no-repeat, repeat;background-position:right .7em top 50%, 0 0;background-size:.65em auto, 100%}.select:hover{border-color:#888}.select:focus{border-color:#aaa;box-shadow:0 0 1px 3px rgba(59,153,252,0.7);box-shadow:0 0 0 3px -moz-mac-focusring;color:#222;outline:none}.select option{font-weight:normal}.select--error{border:1px solid #ffa8a8 !important;color:#c52424}


.form{padding:0.5rem;width:100%}@media (min-width: 1025px){.form{padding:1rem}}.form__button{-webkit-align-items:center;align-items:center;background-color:#868e96;border-radius:5px;color:#fff;display:-webkit-flex;display:flex;font-weight:800;padding:0.5rem 1.1rem;transition:all 0.3s ease-in-out}.form__button:hover{background-color:#362469;transition:all 0.3s ease-in-out}.form__container{-webkit-align-items:flex-end;align-items:flex-end;background-color:#f4f4f4;border-radius:12px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;margin:2rem auto !important;opacity:0.9;position:relative}@media (min-width: 1025px){.form__container{width:50%}}.form h1{color:#444;font-family:'Montserrat', Helvetica, Arial, sans-serif;font-size:1.4rem;font-weight:400;letter-spacing:-0.05rem;line-height:1.7}.form label{background-color:#f4f4f4;color:#444;display:inline-block;font-size:0.8rem;font-weight:800;padding:0rem 1.1rem;-webkit-transform:translate(20px, 33px);transform:translate(20px, 33px);z-index:9}.form__section{border:1px solid rgba(172,170,170,0.2);border-top:4px solid #362469;border-radius:12px;box-shadow:0 0 20px #a5a5a52f;margin:2rem auto;padding:1rem;width:90%}.form__section:last-of-type{-webkit-align-items:center;align-items:center;border:0;box-shadow:none;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;margin:0 auto;padding:0 2rem 2rem 2rem}@media (min-width: 1025px){.form__section{padding:2rem}}

.footer{color:yellow}

.header{background-color:#fff;border-bottom:1px dashed rgba(0,0,0,0.2);color:#222;height:100px;width:100%}.header__container{-webkit-align-items:center;align-items:center;display:-webkit-flex;display:flex;height:100%;-webkit-justify-content:space-between;justify-content:space-between;max-width:1080px;margin:0 auto;width:80%}.header__text{-webkit-flex-grow:2;flex-grow:2;text-align:right}

.app{margin:0 auto;width:100%}

*{box-sizing:border-box}body{background-color:#fff;margin:0;font-family:"Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

