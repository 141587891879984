@import '../../styles/main';

.modal {
  background-color: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 0px 0px 50px 1000px rgba(0, 0, 0, 0.8);
  height: 700px;
  left: 50%;
  overflow-y: scroll;
  top: 20px;
  position: fixed;
  transform: translateX(-50%);
  width: 90%;
  z-index: 999999;

  @include desktop {
    width: 70%;
  }
}
