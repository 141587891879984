.header {
    background-color: rgb(255, 255, 255);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    color: rgba(34, 34, 34, 1);
    height: 100px;
    width: 100%;

    &__container {
        align-items: center;
        display: flex;
        height: 100%;;
        justify-content: space-between;
        max-width: 1080px;
        margin: 0 auto;
        width: 80%;
    }

    &__logo {
    }

    &__text {
        flex-grow: 2;
        text-align: right;
    }
}